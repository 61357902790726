


























import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { ITheoryQuestionWrongAnswer } from "@/interfaces/ITheoryQuestionWrongAnswer";
import { namespace } from "vuex-class";
import WrongQuestionTileModal from "@/views/Student/LearnStatus/WrongQuestionTileModal.vue";
import FscModal from "@/components/Modal/FscModal.vue";

const StudentQuestionAnswersModule = namespace("student-question-answers");

@Component({
  components: {
    FscModal,
    FscSimpleCard,
    WrongQuestionTileModal,
  },
})
export default class LearnStatusWrongQuestionTile extends Vue {
  public name = "LearnStatusWrongQuestionTile";

  @StudentQuestionAnswersModule.Action("findWrongAnswerById")
  public findWrongAnswerById: any;

  @StudentQuestionAnswersModule.Getter("getWrongAnswer")
  public wrongAnswer: any;

  protected fields = [
    {
      label: "",
      key: "officialNumber",
      sortable: false,
    },
    {
      label: "",
      key: "title",
      sortable: false,
    },
    {
      label: "",
      key: "numberOfWrongAnswers",
      sortable: false,
    },
  ];

  @Prop({ type: Array, required: false })
  public theoryQuestionMoreThanTwoWrongAnswers!: Array<ITheoryQuestionWrongAnswer>;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  @Prop()
  public studentId!: any;

  public async onQuestionClick(question: any): Promise<any> {
    const theoryQuestionId = question.theoryQuestionId;
    const studentId = this.studentId;
    const options = {
      theoryQuestionId: theoryQuestionId,
      studentId: studentId,
    };
    await this.findWrongAnswerById(options);
    this.$bvModal.show("question-answer-modal");
  }
}
