

















import { Component, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import PreliminaryExamImage from "@/views/Student/LearnStatus/PreliminaryExam/PreliminaryExamImage.vue";

@Component({
  components: {
    FscSimpleCard,
    PreliminaryExamImage,
  },
})
export default class PreliminaryExam extends Vue {
  public name = "PreliminaryExam";

  @Prop()
  public studentEducations!: any;

  @Prop()
  public topActiveEducation!: any;

  public get studentEducation() {
    if (this.studentEducations[this.topActiveEducation]) {
      return this.studentEducations[this.topActiveEducation];
    }
    return null;
  }
  public get learnStatus() {
    if (this.studentEducations[this.topActiveEducation]) {
      return this.studentEducations[this.topActiveEducation].learnStatus;
    }
    return 0;
  }
}
