






























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import AnswerMultiple from "@/views/Student/LearnStatus/PreExamsList/AnswerMultiple.vue";
import AnswerFree from "@/views/Student/LearnStatus/PreExamsList/AnswerFree.vue";
import WrongQuestionAnswerMultiple from "@/views/Student/LearnStatus/WrongQuestionAnswerMultiple.vue";
import WrongQuestionAnswerFree from "@/views/Student/LearnStatus/WrongQuestionAnswerFree.vue";

const TheoryQuestionsModule = namespace("theory-questions");

@Component({
  components: {
    FscSimpleCard,
    AnswerMultiple,
    AnswerFree,
    WrongQuestionAnswerMultiple,
    WrongQuestionAnswerFree,
  },
})
export default class WrongQuestionTileModal extends Vue {
  public name = "WrongQuestionTileModal";

  @TheoryQuestionsModule.Action("findTheoryQuestionById")
  public findTheoryQuestionById: any;

  @TheoryQuestionsModule.Getter("getTheoryQuestion")
  public theoryQuestion: any;

  @Prop()
  public wrongAnswer!: any;

  public questionClass: any = "";

  public isMultipleType: any = null;
  public isFreeTextType: any = null;
  public isMultipleChoiceWithMediaType: any = null;

  public async mounted(): Promise<any> {
    if (this.wrongAnswer) {
      const theoryQuestionId = this.wrongAnswer.theoryQuestionId;
      const studentEducationId = this.wrongAnswer.studentEducationId;
      const options = {
        theoryQuestionId: theoryQuestionId,
        studentEducationId: studentEducationId,
      };
      await this.findTheoryQuestionById(options);
      this.questionClassName(this.theoryQuestion);
      this.isMultipleType = this.isMultipleChoice(this.theoryQuestion);
      this.isFreeTextType = this.isFreeText(this.theoryQuestion);
      this.isMultipleChoiceWithMediaType = this.isMultipleChoiceWithMedia(this.theoryQuestion);
    }
  }

  public isMultipleChoice(question: any) {
    return question != null && question.questionType === "MULTIPLE_CHOICE";
  }

  public isFreeText(question: any) {
    return question != null && question.questionType === "FREE_TEXT";
  }

  public isMultipleChoiceWithMedia(question: any) {
    return question != null && question.questionType === "MULTIPLE_CHOICE_WITH_MEDIA";
  }

  public questionClassName(question: any): void {
    switch (question.questionClass) {
      case "G":
        this.questionClass = "Grundstoff";
        break;
      case "GM":
        this.questionClass = "Grundstoff";
        break;
      case "G,GM":
        this.questionClass = "Grundstoff";
        break;
      default:
        this.questionClass = question.questionClass;
    }
  }
}
