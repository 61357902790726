

























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IStudentQuestionAnswerMetrics } from "@/interfaces/IStudentQuestionAnswerMetrics";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import UserService from "@/services/UserService";

@Component({
  components: {
    FscHorizontalTable,
    FscSimpleCard,
  },
})
export default class LearnStatusStatisticsTile extends Vue {
  public name = "LearnStatusStatisticsTile";

  protected columns = [
    {
      label: this.$tc("general.total_learning_time", 1),
      key: "totalLearningTimeInMinutes",
    },
    {
      label: this.$tc("general.processing_time_per_question", 1),
      key: "processingTimePerQuestionInSeconds",
    },
    {
      label: this.$tc("general.longest_session", 1),
      key: "longestSessionInMinutes",
    },
    {
      label: this.$tc("general.percentage_correct_answer_first_time", 1),
      key: "percentageCorrectAnswerFirstTime",
    },
    {
      label: this.$tc("general.percentage_correct_answer_second_time", 1),
      key: "percentageCorrectAnswerSecondTime",
    },
    {
      label: this.$tc("general.days_since_first_question_answer", 1),
      key: "daysSinceFirstQuestionAnswer",
    },
    {
      label: this.$tc("general.days_between_first_question_and_passing_theory_exam", 1),
      key: "daysBetweenFirstQuestionAndPassingTheoryExam",
    },
    {
      label: this.$tc("general.hours_after_last_learning_session", 1),
      key: "hoursAfterLastLearningSession",
    },
    {
      label: this.$tc("general.total_questions_answers", 1),
      key: "totalQuestionsAnswers",
    },
  ];
  public tab = 0;

  @Prop()
  public topActiveEducation!: any;

  @Prop({ type: Array, required: false })
  public studentQuestionAnswerMetrics!: Array<{
    metrics: IStudentQuestionAnswerMetrics;
    studentEducationId: number;
    licenseClass: string;
  }>;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  protected formatMinutes(minutes: number | null): string {
    if (minutes == null) return "";
    return `${minutes} ${this.$t("general.minute")}`;
  }

  protected formatSeconds(seconds: number | null): string {
    if (seconds == null) return "";
    return `${seconds} ${this.$t("general.second")}`;
  }

  protected formatPercents(percents: number | null): string {
    if (percents == null) return "";
    return `${percents}%`;
  }

  protected formatDays(days: number | null): string {
    if (days == null) return "";
    return `${days} ${this.$t("general.days")}`;
  }

  protected formatHours(hours: number | null): string {
    if (hours == null) return "";
    return `${hours} ${this.$t("general.hours")}`;
  }

  protected formatTotalNumber(total: number | null): string {
    if (total == null) return "";
    return `${total.toLocaleString("de-DE")}`;
  }

  protected changeTab(index: number): void {
    this.tab = index;
  }

  @Watch("topActiveEducation", { immediate: true, deep: true })
  public onTopActiveEducationChange(active: any) {
    this.tab = active;
  }

  public get studentQuestionAnswerMetric() {
    // Come one record
    return this.studentQuestionAnswerMetrics.length ? this.studentQuestionAnswerMetrics[0] : {};
  }

  public get isSupportUser() {
    if (UserService.getUser().username.startsWith("support@")) {
      return true;
    }
    return false;
  }
}
