import { render, staticRenderFns } from "./WrongQuestionTileModal.vue?vue&type=template&id=2f95ba61&scoped=true"
import script from "./WrongQuestionTileModal.vue?vue&type=script&lang=ts"
export * from "./WrongQuestionTileModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f95ba61",
  null
  
)

export default component.exports