




















































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StudentQuestionsImgNegative from "@/views/Student/LearnStatus/StudentQuestionsImgNegative.vue";
import StudentQuestionsImgNeutral from "@/views/Student/LearnStatus/StudentQuestionsImgNeutral.vue";
import StudentQuestionsImgPositive from "@/views/Student/LearnStatus/StudentQuestionsImgPositive.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

const StudentQuestionAnswersModule = namespace("student-question-answers");
const TheoryQuestionsModule = namespace("theory-questions");

@Component({
  components: {
    FscSimpleCard,
    StudentQuestionsImgNegative,
    StudentQuestionsImgNeutral,
    StudentQuestionsImgPositive,
  },
})
export default class StudentQuestions extends Vue {
  public name = "StudentQuestions";

  @Prop()
  public studentEducations!: any;

  @Prop()
  public topActiveEducation!: any;

  @StudentQuestionAnswersModule.Action("getStats")
  public getStatusByEducationId: any;

  @StudentQuestionAnswersModule.Getter("getStats")
  public stats: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public statsLoading: any;

  @TheoryQuestionsModule.Action("findLearningModeByEducationId")
  public findLearningModeByEducationId: any;

  @TheoryQuestionsModule.Getter("getDataItem")
  public learningMode: any;

  public learningModeDoubleChecked: any = "";

  @Prop()
  public educationId!: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  @Watch("topActiveEducation", { immediate: true, deep: true })
  public async onTopActiveEducationChange(active: any) {
    this.loading = true;
    await this.getStatusByEducationId(this.studentEducations[active].id);
    await this.findLearningModeByEducationId(this.studentEducations[active].id);
    if (this.learningMode && this.learningMode.id === 2) {
      this.learningModeDoubleChecked = true;
    } else {
      this.learningModeDoubleChecked = false;
    }
    this.loading = false;
  }
}
