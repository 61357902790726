
















































import { Vue, Component, Prop } from "vue-property-decorator";
import ThemeProgressIndicator from "./ThemeProgressIndicator.vue";
import ThemeItem from "./ThemeItem.vue";

@Component({
  components: {
    ThemeProgressIndicator,
    ThemeItem,
  },
})
export default class CategoryList extends Vue {
  public name = "CategoryList";

  @Prop({ type: Array, default: () => [], required: true })
  public list!: any;

  public activeIndex: number | null = null; // Track which category is open

  public toggleAccordion(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  public handleClick(id: string): void {
    this.$emit("openSubcategory", id);
  }
}
