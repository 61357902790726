










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Table from "@/components/Table.vue";
import PreExamsListQuestions from "@/views/Student/LearnStatus/PreExamsList/PreExamsListQuestions.vue";
import { namespace } from "vuex-class";
import { IStudentEducation } from "@/interfaces/IStudentEducation";

const TheoryPreExamsModule = namespace("theory-pre-exams");

@Component({
  components: {
    FscSimpleCard,
    Table,
    PreExamsListQuestions,
  },
})
export default class PreExamsList extends Vue {
  public name = "PreExamsList";

  @TheoryPreExamsModule.Action("theoryPreExamsByStudentId")
  public theoryPreExamsByStudentId: any;

  @TheoryPreExamsModule.Getter("getDataList")
  public theoryPreExams: any;

  @TheoryPreExamsModule.Getter("getIsLoading")
  public isLoading: any;

  @Prop()
  public studentId!: any;

  @Prop()
  public topActiveEducation!: any;

  @Prop({ type: Array, default: () => [] })
  public educationsByStudent!: Array<IStudentEducation>;

  public selectedPreExam: any = null;

  public fields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
      class: "w-25",
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      class: "w-25",
    },
    {
      key: "studentEducation",
      label: this.$t("calendar.class"),
      class: "w-prc-40",
    },
    {
      key: "points",
      label: "Fehler",
      class: "w-prc-10",
    },
  ];

  public async mounted(): Promise<any> {
    if (this.studentId) await this.theoryPreExamsByStudentId(this.studentId);
  }

  public formatStudentEducation(education: any) {
    const licenseClass = education ? education.name : "";
    return licenseClass;
  }

  public pointsColor(passed: any) {
    if (!passed) {
      return `table-label points-label-false d-flex justify-content-center`;
    }
    return `table-label points-label-true d-flex justify-content-center`;
  }

  public onPreExamRowClicked(preExam: any): void {
    this.selectedPreExam = preExam;
    this.$router.push({
      name: "PreExamsListQuestions",
      params: { id: String(preExam.id), class: preExam.studentEducation.name },
    });
  }

  public tableHeight() {
    if (this.selectedPreExam) {
      return `h-50`;
    }
    return `h-75`;
  }

  // @Watch("studentId", { immediate: true, deep: true})
  // public onStudentIdChange(id: any): void {
  //   if (id) {
  //     this.theoryPreExamsByStudentId(id);
  //   }
  // }

  public get filteredTheoryPreExamsByEducation() {
    if (this.topActiveEducation !== null && this.topActiveEducation >= 0 && this.topActiveEducation < this.educationsByStudent.length) {
      return this.theoryPreExams.filter((pe: { studentEducationId: number }) => pe.studentEducationId === this.educationsByStudent[this.topActiveEducation].id);
    } else {
      return [];
    }
  }
}
