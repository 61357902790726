






import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ThemeItem extends Vue {
  public name = "ThemeItem";

  @Prop({ type: Boolean, default: false })
  public filled!: boolean;
}
