






































import { Vue, Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import PreliminaryExamImage from "@/views/Student/LearnStatus/PreliminaryExam/PreliminaryExamImage.vue";
import { namespace } from "vuex-class";
import ThemeProgressIndicator from "@/views/Student/LearnStatus/ThemeLooper/Partials/ThemeProgressIndicator.vue";

const LMSThemeLoopModule = namespace("lms-theme-loop");

@Component({
  components: { PreliminaryExamImage, FscSimpleCard, ThemeProgressIndicator },
})
export default class ThemeLooper extends Vue {
  @Prop()
  public educationsByStudent!: any;

  @Prop()
  public topActiveEducation!: any;

  @LMSThemeLoopModule.Getter("getBaseCategory")
  public studentBaseCategorySubcategoryData!: any;

  @LMSThemeLoopModule.Getter("getEducationCategory")
  public studentEducationCategorySubcategoryData!: any;

  public get activeEducation() {
    const licenseClassWithKeyNumber = this.educationsByStudent?.[this.topActiveEducation]?.licenseClassWithKeyNumber;

    if (!licenseClassWithKeyNumber) {
      return { licenseClass: "", keyNumber: "" }; // Default safe values
    }

    return {
      licenseClass: licenseClassWithKeyNumber.licenseClass || "",
      keyNumber: licenseClassWithKeyNumber.keyNumber || "",
    };
  }

  public get activeEducationCategory() {
    if (!this.activeEducation.licenseClass) {
      return null; // Avoid breaking the app
    }

    const data = this.studentEducationCategorySubcategoryData.find((item: any) => item.name === this.activeEducation.licenseClass);

    // This will modify 'studentEducationCategorySubcategoryData item name' if there is a keyNumber in activeEducation
    if (data && this.activeEducation.keyNumber) {
      return { ...data, name: `${data.name}${this.activeEducation.keyNumber}` };
    }

    return data || null;
  }
}
