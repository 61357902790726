










































import { Vue, Component, Prop } from "vue-property-decorator";
import CategoryList from "@/views/Student/LearnStatus/ThemeLooper/Partials/CategoryList.vue";
import ThemeItem from "@/views/Student/LearnStatus/ThemeLooper/Partials/ThemeItem.vue";
import ThemeProgressIndicator from "@/views/Student/LearnStatus/ThemeLooper/Partials/ThemeProgressIndicator.vue";

@Component({
  components: {
    ThemeItem,
    ThemeProgressIndicator,
    CategoryList,
  },
})
export default class StudentCategoriesSubcategories extends Vue {
  public name = "StudentCategoriesSubcategories";

  @Prop()
  public baseCategoryData!: any;

  @Prop()
  public educationCategoryData!: any;

  public forwardSubcategoryOpen(id: string, name: string) {
    this.$emit("openSubcategory", id, name);
  }
}
