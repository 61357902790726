






















import { Vue, Component, Prop } from "vue-property-decorator";
import StudentCategoriesSubcategories from "@/views/Student/LearnStatus/ThemeLooper/StudentCategoriesSubcategories.vue";
import StudentSubcategoriesDetailed from "./StudentSubcategoriesDetailed.vue";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

const LMSThemeLoopModule = namespace("lms-theme-loop");

@Component({
  components: {
    FscSimpleCard,
    StudentCategoriesSubcategories,
    StudentSubcategoriesDetailed,
  },
})
export default class ThemeLooperTile extends Vue {
  @Prop()
  public studentId!: any;

  @Prop()
  public educationsByStudent!: any;

  @Prop()
  public topActiveEducation!: any;

  @LMSThemeLoopModule.Action("findStudentCategoriesSubcategories")
  public findStudentCategoriesSubcategories!: (studentId: any) => void;

  @LMSThemeLoopModule.Action("findStudentSubcategoryDetailed")
  public findStudentSubcategoryDetailed!: (options: any) => void;

  @LMSThemeLoopModule.Getter("getBaseCategory")
  public studentBaseCategorySubcategoryData!: any;

  @LMSThemeLoopModule.Getter("getEducationCategory")
  public studentEducationCategorySubcategoryData!: any;

  @LMSThemeLoopModule.Getter("getStudentSubcategoryDetails")
  public studentSubcategoryDetails!: any;

  @LMSThemeLoopModule.Getter("getStudentSubcategoryQuestions")
  public studentSubcategoryQuestions!: any;

  @LMSThemeLoopModule.Getter("getIsLoading")
  public loading!: any;

  public showQuestions = true;

  public mounted() {
    this.showQuestions = false;
    this.findStudentCategoriesSubcategories(this.studentId);
  }

  public handleOpenSubcategory(officialNumber: string, name: string) {
    const options = {
      studentId: this.studentId,
      subCategoryOfficialNumber: officialNumber,
      licenceClass: name,
    };
    this.findStudentSubcategoryDetailed(options);
    this.toggleSubcategory();
  }

  public toggleSubcategory() {
    this.showQuestions = true;
  }

  public get activeEducation() {
    const licenseClassWithKeyNumber = this.educationsByStudent?.[this.topActiveEducation]?.licenseClassWithKeyNumber;

    if (!licenseClassWithKeyNumber) {
      return { licenseClass: "", keyNumber: "" }; // Default safe values
    }

    return {
      licenseClass: licenseClassWithKeyNumber.licenseClass || "",
      keyNumber: licenseClassWithKeyNumber.keyNumber || "",
    };
  }

  public get activeEducationCategory() {
    if (!this.activeEducation.licenseClass) {
      return null; // Avoid breaking the app
    }

    // This will modify 'studentEducationCategorySubcategoryData item name' if there is a keyNumber in activeEducation
    const data = this.studentEducationCategorySubcategoryData.find((item: any) => item.name === this.activeEducation.licenseClass);

    if (data && this.activeEducation.keyNumber) {
      return { ...data, name: `${data.name}${this.activeEducation.keyNumber}` };
    }

    return data || null;
  }
}
