









































import { Vue, Component, Prop } from "vue-property-decorator";
import CategoryList from "@/views/Student/LearnStatus/ThemeLooper/Partials/CategoryList.vue";
import ThemeItem from "@/views/Student/LearnStatus/ThemeLooper/Partials/ThemeItem.vue";
import ThemeProgressIndicator from "@/views/Student/LearnStatus/ThemeLooper/Partials/ThemeProgressIndicator.vue";

@Component({
  components: {
    ThemeItem,
    ThemeProgressIndicator,
    CategoryList,
  },
})
export default class StudentSubcategoriesDetailed extends Vue {
  public name = "StudentSubcategoriesDetailed";

  @Prop()
  public details!: any;

  @Prop()
  public questions!: any;

  public handleClick(): void {
    this.$emit("goBack");
  }
}
