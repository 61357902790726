













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ThemeProgressIndicator extends Vue {
  public name = "ThemeProgressIndicator";

  @Prop({ type: Number, default: 0, required: true })
  public questionCount!: number;

  @Prop({ type: Number, default: 0, required: true })
  public correctQuestionCount!: number;

  @Prop({ type: Boolean, default: false })
  public fat!: boolean;

  get progress(): number {
    return this.questionCount > 0 ? Math.floor((this.correctQuestionCount / this.questionCount) * 100) : 0;
  }
}
